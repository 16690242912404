module.exports = [{
      plugin: require('../node_modules/@gatsbywpthemes/gatsby-theme-wp-search/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://api.itsnot42.com/graphql","excludeFieldNames":["blocksJSON","saveContent"],"presets":[{"presetName":"DEVELOP","options":{"type":{"__all":{"limit":null},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true}}],"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TXLCJPJ","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"dataLayerName":"dataLayer","routeChangeEventName":"route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/@gatsbywpthemes/gatsby-theme-blog-data/gatsby-browser.js'),
      options: {"plugins":[],"webfontsOptions":{"fonts":{"google":[{"family":"Special Elite"},{"family":"Lexend","variants":["400","400i","700","700i"]}]}},"wordPressUrl":"https://api.itsnot42.com","googleTagManagerId":"GTM-TXLCJPJ","googleTagManagerOptions":{"includeInDevelopment":false,"defaultDataLayer":{"platform":"gatsby"},"dataLayerName":"dataLayer","routeChangeEventName":"route-change","enableWebVitalsTracking":true},"addSiteMap":true,"siteMapOptions":{},"logo":null,"darkModeLogo":null},
    },{
      plugin: require('../node_modules/@gatsbywpthemes/gatsby-plugin-wordpress-lightbox/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@gatsbywpthemes/gatsby-plugin-gwpt-packages/gatsby-browser.js'),
      options: {"plugins":[],"wordPressUrl":"https://api.itsnot42.com","pathPrefix":"","paginationPrefix":"page","addWordPressComments":true,"addWordPressSearch":false,"gaTrackingId":null,"gaOptions":{},"googleTagManagerId":"GTM-TXLCJPJ","googleTagManagerOptions":{"includeInDevelopment":false,"defaultDataLayer":{"platform":"gatsby"},"dataLayerName":"dataLayer","routeChangeEventName":"route-change","enableWebVitalsTracking":true},"addSiteMap":true,"siteMapOptions":{},"widgetAreas":{"slideMenuWidgets":["Categories","RecentPosts","Tags","SocialFollow"],"sidebarWidgets":["Categories","RecentPosts","Tags","SocialFollow"]},"archiveSidebarPosition":"right","pageCreateDebugOutput":false,"logo":null,"darkModeLogo":null,"socialFollowLinks":[],"overrideWPColors":false,"developLimit":null,"addColorModes":true,"webfontsOptions":{"fonts":{"google":[{"family":"Special Elite"},{"family":"Lexend","variants":["400","400i","700","700i"]}]}},"gatsbySourceWordPressOptions":{"presets":[]},"createPages":true,"createPosts":true,"createCategories":true,"createTags":true,"createUsers":true,"withApollo":true,"fonts":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.1,"once":true,"selector":"[data-sal], .entry-content .animate-on-scroll"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
